<template>
    <div class="">
        
      <section id="property" class="padding bg_light">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">

                    <h2 v-if="pgLoading" class="uppercase webkit-animation webkit-25per-35 mb-5"></h2>
                    <p v-if="pgLoading" class="heading_space webkit-animation webkit-50per-25"></p>

                    <h2 v-if="!pgLoading" 
                    :style="($i18n.locale == 'ar') ? 'text-align:right;' : ''"
                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : 'uppercase'"
                        v-html="($i18n.locale == 'ar') ? setting.title_ar : setting.title_en">
                    </h2>
                    <p  v-if="!pgLoading" 
                        class="heading_space"
                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                        v-html="($i18n.locale == 'ar') ? setting.body_ar : setting.body_en">
                    </p>

                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">

                    <div id="full-slider" class="owl-crousel owl-thme">
                        <div class="owl-controls clickable">
                            <div class="owl-buttons">
                                <div @click="prev()" 
                                    id="swiperBtnPrevBD" 
                                    class="owl-prev">
                                    <span class="fa fa-angle-left"></span>
                                </div>
                                <div @click="next()" 
                                    id="swiperBtnNextBD" 
                                    class="owl-next">
                                    <span class="fa fa-angle-right"></span>
                                </div>
                            </div>
                        </div>
                    </div> 


                <Swiper v-if="pgLoading" ref="mySwiper" :options="swiperOptions">
                    <SwiperSlide v-for="(row, index) in 4" :key="index">

                        <div class="item feature_item">
                            <div class="image">
                            <div class="webkit-animation webkit-100per-255"></div>
                            <span class="price default_clr webkit-animation webkit-50per-30"></span>
                            </div>
                            <div class="proerty_content">
                            <div class="proerty_text">
                                <h3 class="bottom15 webkit-animation webkit-50per-50"></h3>
                                <p class="webkit-animation webkit-100per-150 bottom10"></p>
                                <p class="webkit-animation webkit-50per-30"></p>
                            </div>

                            <div class="property_meta"> 
                                <span><span class="webkit-animation webkit-40-20"></span></span> 
                                <span><span class="webkit-animation webkit-40-20"></span></span> 
                                <span><span class="webkit-animation webkit-40-20"></span></span> 
                            </div>
                            </div>
                        </div>

                    </SwiperSlide>
                </Swiper>
                

                <Swiper v-if="!pgLoading" ref="mySwiperBD" :options="swiperOptions">
                    <SwiperSlide v-for="(item, index) in items" :key="index">

                        <div class="item">
                            <div class="feature_item">

                                <div class="image">

                                    <router-link v-if="$i18n.locale == 'en'" :to="'/properties/'+item.slug">
                                        <img 
                                            v-lazy="(item.image) ? item.image.url : ''" 
                                            :src="(item.image) ? item.image.url : ''" 
                                            :alt="($i18n.locale == 'ar') ? item.image.ar.image_alt : item.image.en.image_alt"
                                            :title="($i18n.locale == 'ar') ? item.image.ar.image_title : item.image.en.image_title"
                                            class="img-responsive">
                                    </router-link>

                                    <router-link v-if="$i18n.locale == 'ar'" :to="'/properties/'+item.slug+'?lang=ar'">
                                        <img
                                                v-lazy="(item.image) ? item.image.url : ''"
                                                :src="(item.image) ? item.image.url : ''"
                                                :alt="($i18n.locale == 'ar') ? item.image.ar.image_alt : item.image.en.image_alt"
                                                :title="($i18n.locale == 'ar') ? item.image.ar.image_title : item.image.en.image_title"
                                                class="img-responsive">
                                    </router-link>
                                    <div class="price clearfix">
                                        <span class="tag pull-right">{{ item.price }}</span>
                                    </div>

                                    <span class="tag_t">for Rent</span> 
                                    <span class="tag_l" v-if="item.isFeatured">Featured</span>
                                </div>

                                <div class="proerty_content">
                                    <div class="proerty_text">
                                        <h3 class="captlize">
                                            <router-link v-if="$i18n.locale == 'en'" :to="'/properties/'+item.slug">
                                            {{ ($i18n.locale == 'ar') ? item.title_ar : item.title_en }}
                                            </router-link>
                                            <router-link v-if="$i18n.locale == 'ar'" :to="'/properties/'+item.slug+'?lang=ar'">
                                                {{ ($i18n.locale == 'ar') ? item.title_ar : item.title_en }}
                                            </router-link>
                                        </h3>
                                        <p>{{ (item.location) ?($i18n.locale == 'ar') ? item.location.ar.title : item.location.en.title : '' }}</p>
                                    </div>

                                    <div class="property_meta transparent" style='margin-left: 20px'> 
                                        <span>
                                            <span v-if="item.icons[0]">
                                                <img 
                                                    :src="(item.icons[0].icon) ? item.icons[0].icon.image.url : ''" 
                                                    :alt="(item.icons[0].icon) ?($i18n.locale == 'ar') ? item.icons[0].icon.image.ar.image_alt : item.icons[0].icon.image.en.image_alt : ''"
                                                    :title="(item.icons[0].icon) ?($i18n.locale == 'ar') ? item.icons[0].icon.image.ar.image_title : item.icons[0].icon.image.en.image_title : ''"
                                                    style="width:20px; height:20px">
                                                {{ item.icons[0].value }}
                                                {{ ($i18n.locale == 'ar') ? item.icons[0].icon.ar.title : item.icons[0].icon.en.title }}
                                            </span>
                                        </span>
                                        <span>
                                            <span v-if="item.icons[1]">
                                                <img 
                                                    :src="(item.icons[1].icon) ? item.icons[1].icon.image.url : ''" 
                                                    :alt="(item.icons[1].icon) ?($i18n.locale == 'ar') ? item.icons[1].icon.image.ar.image_alt : item.icons[1].icon.image.en.image_alt : ''"
                                                    :title="(item.icons[1].icon) ?($i18n.locale == 'ar') ? item.icons[1].icon.image.ar.image_title : item.icons[1].icon.image.en.image_title : ''"
                                                    style="width:20px; height:20px">
                                                {{ item.icons[1].value }}
                                                {{ ($i18n.locale == 'ar') ? item.icons[1].icon.ar.title : item.icons[1].icon.en.title }}
                                            </span>
                                        </span> 
                                        <span>
                                            <span v-if="item.icons[2]">
                                                <img v-if="item.icons[2]"
                                                    :src="(item.icons[2].icon) ? item.icons[2].icon.image.url : ''" 
                                                    :alt="(item.icons[2].icon) ?($i18n.locale == 'ar') ? item.icons[2].icon.image.ar.image_alt : item.icons[2].icon.image.en.image_alt : ''"
                                                    :title="(item.icons[2].icon) ?($i18n.locale == 'ar') ? item.icons[2].icon.image.ar.image_title : item.icons[2].icon.image.en.image_title : ''"
                                                    style="width:20px; height:20px">
                                                {{ item.icons[2].value }}
                                                {{ ($i18n.locale == 'ar') ? item.icons[2].icon.ar.title : item.icons[2].icon.en.title }}
                                            </span>
                                        </span>   
                                    </div>
                                    <div class="property_meta transparent bottom30" style='margin-left: 20px'> 
                                        <span>
                                            <span v-if="item.icons[3]">
                                                <img 
                                                    :src="(item.icons[3].icon) ? item.icons[3].icon.image.url : ''" 
                                                    :alt="(item.icons[3].icon) ?($i18n.locale == 'ar') ? item.icons[3].icon.image.ar.image_alt : item.icons[3].icon.image.en.image_alt : ''"
                                                    :title="(item.icons[3].icon) ?($i18n.locale == 'ar') ? item.icons[3].icon.image.ar.image_title : item.icons[3].icon.image.en.image_title : ''"
                                                    style="width:20px; height:20px">
                                                {{ item.icons[3].value }}
                                                {{ ($i18n.locale == 'ar') ? item.icons[3].icon.ar.title : item.icons[3].icon.en.title }}
                                            </span>
                                        </span> 
                                        <span>
                                            <span v-if="item.icons[4]">
                                                <img 
                                                    :src="(item.icons[4].icon) ? item.icons[4].icon.image.url : ''" 
                                                    :alt="(item.icons[4].icon) ?($i18n.locale == 'ar') ? item.icons[4].icon.image.ar.image_alt : item.icons[4].icon.image.en.image_alt : ''"
                                                    :title="(item.icons[4].icon) ?($i18n.locale == 'ar') ? item.icons[4].icon.image.ar.image_title : item.icons[4].icon.image.en.image_title : ''"
                                                    style="width:20px; height:20px">
                                                {{ item.icons[4].value }}
                                                {{ ($i18n.locale == 'ar') ? item.icons[4].icon.ar.title : item.icons[4].icon.en.title }} 
                                            </span>
                                        </span> 
                                        <span>
                                            <span v-if="item.icons[5]">
                                                <img 
                                                    :src="(item.icons[5].icon) ? item.icons[5].icon.image.url : ''" 
                                                    :alt="(item.icons[5].icon) ?($i18n.locale == 'ar') ? item.icons[5].icon.image.ar.image_alt : item.icons[5].icon.image.en.image_alt : ''"
                                                    :title="(item.icons[5].icon) ?($i18n.locale == 'ar') ? item.icons[5].icon.image.ar.image_title : item.icons[5].icon.image.en.image_title : ''"
                                                    style="width:20px; height:20px">
                                                {{ item.icons[5].value }}
                                                {{ ($i18n.locale == 'ar') ? item.icons[5].icon.ar.title : item.icons[5].icon.en.title }} 
                                            </span>
                                        </span> 
                                    </div>
                                    
                                    <div class="favroute clearfix">
                                        <p class="pull-md-left">{{ item.created_at }} &nbsp; <i class="icon-calendar2"></i></p>
                                    </div>
                                </div>
                            </div>
                      </div>

                    </SwiperSlide>
                </Swiper>


            </div>
          </div>

      </div>
    </section>

    </div>
</template>


<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
    name: 'Featured',
    components: {
        Swiper,
        SwiperSlide
    },
    directives: {
        swiper: directive
    },
    data(){
        return {
            pgLoading: true,
            items: [],
              
            swiperOptions: {
                name: 'mySwiperBD',
                loop: true,
                speed: 700,
                autoplay: true,
                spaceBetween: 30,
                slidesPerView: 3,
                navigation: {
                    nextEl: '#swiperBtnNextBD',
                    prevEl: '#swiperBtnPrevBD'
                },
                breakpoints: {
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20
                },
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10
                }
              },
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            },
        }
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper
        }
    },
    mounted() {},
    created() {
        //
        this.fetchData();
    },
    methods: {
        //
        fetchData() {
            this.pgLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            };
            const options = {
                url: window.baseURL+'/properties',
                method: 'GET',
                data: {},
                params: {
                    bestDeal: true,
                    paginate: 3
                }
            }
            this.axios(options)
                .then(res => {
                  this.pgLoading = false;
                  this.setting = res.data.setting;
                  this.items = res.data.items;

                })
                .catch(() => {})
                .finally(() => {});
        },

        prev() {
            this.$refs.mySwiper.$swiper.slidePrev();
        },
        
        next() {
            this.$refs.mySwiper.$swiper.slideNext();
        },

},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
